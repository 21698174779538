<template>
  <CCard>
    <CCardHeader>
      <h3>Ver Producto </h3>
    </CCardHeader>

    <CCardBody>
      <div class="form-group">
        <label>Producto:</label>
        <p>{{ registro.producto }}</p>
      </div>
      <div class="form-group">
        <label>Descripción corta:</label>
        <p>{{ registro.descripcion_corta }}</p>
      </div>
      <div class="form-group">
        <label>Descripción:</label>
        <p>{{ registro.descripcion }}</p>
      </div>
      <div class="form-group">
        <label>Condición producto:</label>
        <p>{{ registro.condicion_producto }}</p>
      </div>
      <div class="form-group">
        <label>Texto adj.:</label>
        <p>{{ registro.texto_adjunto }}</p>
      </div>
      <div class="form-group">
        <label>Archivo adj.:</label>
        <p>{{ registro.archivo_adjunto }}</p>
      </div>
      <div class="form-group">
        <label>idioma:</label>
        <p>{{ registro.idioma }}</p>
      </div>
      <div class="form-group">
        <router-link to="/ecoProductos/List">
          <CButton color="secondary">
            <i class="cil-arrow-circle-left"></i>
            Regresar
          </CButton>
        </router-link>
      </div>
    </CCardBody>
  </CCard>
</template>
<script>
import httpService from "@/services/httpService"
export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        id: '',
        atributo: '',
        color: '',
        textura: '',
        orden: '',
        idioma: '',
        grupo: '',
        tipo: '',
        deleted_at: '',
        created_at: '',
        updated_at: ''
      }
    }
  },
  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      if (this.id !== '0') {
        let url = 'eco-productos/' + this.id
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];
      }
    },
  },
  mounted() {
    this.getRegistro();
  }
}
</script>